import React from 'react';
import {  Col, Row, Table } from "antd";

//import axios from 'axios';
//import DataModal from '../components/dataModal';
//import { classif } from '../components/modal';
import PortalModal from '../components/modal/PortalModal';

import TabClassif from './classifierTab';
//import { } from 'antd';
//import {viewClassif} from '../components/viewClassif';
import { reference} from "./helpers";

var tabl;



export default function ClassifEdit() {
  const [classif, setClassif]  = React.useState("",[]);

  var ss = "qq";


  const request = () => {
    return fetch('/api/classifier/table').then(request => {
      return request.json()
    })
  }
  request().then(data => {
    tabl = data.data;
    //setClassif(dat.data, [dat.data])
    //console.log(data.data)
  })
//console.log('qqq ' + tabl.values);
//console.log('eee ' + table()[0])

  const columnsClass = [
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      width: '10%'
    },
    {
      title: 'Наименование классификатора',
      dataIndex: 'name',
      key: 'name',
      //width: '10%'
    },
    {
      title: '',
      dataIndex: '',
      key: 'qq',
      width: '20%',

      render: (_, render) => (

                <PortalModal
                  data={{table: render.table,
                    name: render.name}}

                />

/*
          <span style={{"cursor": 'pointer', color: "blue" }} onClick={()=>{
            classif(render.table);
              }>Открыть</span>*/

        )

    },

  ];




  const columns = [
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      width: '10%'
    },
    {
      title: 'Наименование справочника',
      dataIndex: 'name',
      key: 'name',
      //width: '10%'
    },
    {
      title: '',
      dataIndex: '',
      key: 'qq',
      width: '20%'
    },

  ];



  return (
    <>
    <Row>
      <Col span={11}>
        <br />
        <h5>Классификаторы</h5>
        <br />
        <TabClassif />
         {/* <Table
          size="small"
          dataSource={classifiers}
          columns={columnsClass}
          pagination={false}
          //rowSelection={{
          //    type: selectionType,
          //    ...rowSelection,
          //  }}
          /> */}
      </Col>
      <Col span={11} offset={2}>
        <br />
        <h5>Cправочники</h5>
        <br />
        <Table
          size="small"
          dataSource={reference}
          columns={columns}
          pagination={false}
          scroll={{
            y: 200 * 5,
            x: 10
          }}
          //rowSelection={{
          //    type: selectionType,
          //    ...rowSelection,
          //  }}
        />
      </Col>
      </Row>



    </>

  )
}



