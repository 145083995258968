import React from 'react';
import {Table} from 'antd';

import PortalModal from '../components/modal/PortalModal';

export default function TabClassif()
{
  const [tabData, setTabData] =  React.useState([]);

  React.useEffect(() => {
    fetch("/api/classifier/table")
      .then((response) => {
        return  response.json();

      })
      .then((data) => {
        //console.log(data.data)
        setTabData(data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }, [])

  const columnsClass = [
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      width: '10%'
    },
    {
      title: 'Наименование классификатора',
      dataIndex: 'name',
      key: 'name',
      //width: '10%'
    },
    {
      title: '',
      dataIndex: '',
      key: 'qq',
      width: '20%',

      render: (_, render) => (

                <PortalModal
                  data={{table: render.table,
                    name: render.name}}

                />

/*
          <span style={{"cursor": 'pointer', color: "blue" }} onClick={()=>{
            classif(render.table);
              }>Открыть</span>*/

        )

    },

  ];
//console.log(tabData)




  return (
    <Table
    size="small"
        dataSource={tabData}
        columns={columnsClass}
        pagination={false}

        //rowSelection={{
        //    type: selectionType,
        //    ...rowSelection,
        //  }}
      />

  )

}
