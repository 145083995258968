import React from 'react';

import HazardAssessment from '../../../formik/forms/hazardAssessment';
import MedicalCheckups from '../../../formik/forms/medicalCheckups';
import Training from '../../../formik/forms/training';

export function getTabs({ project, workplace }) {
  const tabs = [
    {
      label: 'Оценка опасностей',
      content: <HazardAssessment project={project} workplace={workplace} />,
    },
    {
      label: 'Медицинские осмотры',
      content: <MedicalCheckups project={project} workplace={workplace} />,
    },
    {
      label: 'Обучение',
      content: <Training project={project} workplace={workplace} />,
    },
    /* {
      label: 'Подбор СИЗ',
      content: null,
    } */
  ];

  return tabs;
}
