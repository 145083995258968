import React from "react";
import {useDispatch} from "react-redux";

import Email from "../../../../assets/svg/email";
import Outlogout from "../../../../assets/svg/outlogout";
import Phone from "../../../../assets/svg/phone";
import Unlocked from "../../../../assets/svg/unlocked";
import * as authActions from "../../../../store/actions/authActions";
import Dialog from '../../../blocks/dialog';

import ChangeEmail from './changeEmail';
import ChangePassword from './changePassword';
import *  as Styled from './styled';

const styled = {
  top: 80,
  right: 100,
  position: 'absolute'
}

export default function SecuritySettings(props) {
  const {
    isRender,
    verifyEmail,
    userName,
    userEmail,
    showBlock,
    onShowBlock,
    contract,
    tail,

  } = props;
  let str = "";
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(authActions.logout());
  };

  const handleSafety = () => {
    onShowBlock('email')
  }

  const handleClose = () => {
    onShowBlock(null)
  }

  return (
    <React.Fragment>
      <Dialog
        isRender={isRender}
        isClose={false}
        centered={false}
        width={350}
        onClose={handleClose}
        styled={styled}
        padding={'20px 24px'}
      >
      <Styled.Buttons
        direction={'column'}
      >
        <Styled.AtomButton onClick={handleSafety}>
          <Unlocked />
          <span>Безопасность</span>
        </Styled.AtomButton>
        <Styled.AtomButton onClick={onLogout}>
          <Outlogout />
          <span>Выход</span>
        </Styled.AtomButton>
      </Styled.Buttons>
      <Styled.H6>Мой тариф</Styled.H6>
      <Styled.Contacts
        tagName={'p'}
        fSize={16}
        align={"center"}
        fWeight={400}
        lHeight={22}
      >
        <span>
            <svg style={{'margin-right': '10px'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bullseye" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
              <path d="M8 13A5 5 0 1 1 8 3a5 5 0 0 1 0 10m0 1A6 6 0 1 0 8 2a6 6 0 0 0 0 12"/>
              <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8"/>
              <path d="M9.5 8a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
            </svg>  <span style={{'margin-right': '10px'}}>{contract} </span> {contract === "Профессионал" && <span style={{'background-color': '#3aace3', 'color': 'white', 'margin-left': '0px', 'border-radius': '20px', 'padding': '0px 8px'}}>
          до {tail}
          </span>}
        </span>
        </Styled.Contacts>
      <Styled.H6>Поддержка</Styled.H6>
      <Styled.Contacts
        tagName={'p'}
        fSize={16}
        align={"center"}
        fWeight={400}
        lHeight={22}
      >
        <Styled.IconContacts>
          <Phone />
        </Styled.IconContacts>
         +7 (499) 164-98-64
      </Styled.Contacts>
      <Styled.Contacts
        tagName={'p'}
        fSize={16}
        align={"center"}
        fWeight={400}
        lHeight={22}
      >
        <Styled.IconContacts>
          <Email />
        </Styled.IconContacts>
         riskhelp@vcot.info
      </Styled.Contacts>
      </Dialog>
      <ChangeEmail
        isRender={showBlock === 'email'}
        verifyEmail={verifyEmail}
        userName={userName}
        userEmail={userEmail}
        onShowBlock={onShowBlock}
      />
      <ChangePassword
        isRender={showBlock === 'password'}
        onShowBlock={onShowBlock}
      />
    </React.Fragment>
  )
}

