import React from 'react';
//import axios from "../../../../../axios/axios";
import Cookies from 'universal-cookie';

import useKeyEvents from '../../../../../hooks/useEventKey';
import Button from '../../../../atoms/nButton';
import Dialog from '../../../../blocks/dialog';
import Portal from '../../../../blocks/portal';
import FieldInput from '../../../fields/input';

import {usePostNewWorkplace} from './hooks';
import * as Styled from './styled';
//import { FieldNumber } from '../../../fields/input/styled';

export default function AddWorkplace(props) {
  const {isRender, id, formik, onShowAddWorkplace} = props;
  const {onPostNewWorkplace} = usePostNewWorkplace({
    id,
    onShowAddWorkplace,
    formik
  });

const cookies = new Cookies();
//console.log(cookies.get('Role'));
//console.log('work');
  useKeyEvents('Enter', () => {
    const {value: parrent } = formik.getFieldProps('title') ?? {};
    const {value} = formik.getFieldProps('addWorkplace.name') ?? {};
    const {num} = formik.getFieldProps('addWorkplace.number') ?? {};
    //const {num} =  formik.getFieldProps('addWorkplace.number').value
    if (!value?.length) {
      return null
    }

    onPostNewWorkplace({
      id: parrent.id,
      name: value,
      num: formik.getFieldProps('addWorkplace.number').value
    });
  });

  const closeShowAddWorkplace = () => {
    formik.setFieldValue('addWorkplace', {});
    onShowAddWorkplace.off();
  }

  const onAddWorkplace = () => {
    const {value: parrent } = formik.getFieldProps('title') ?? {};
    const {value} = formik.getFieldProps('addWorkplace.name') ?? {};
    //const {num} = formik.getFieldProps('addWorkplace.name') ?? {};
    //console.log(formik.getFieldProps('addWorkplace.number').value)
    //console.log(value)
    onPostNewWorkplace({
      id: parrent.id,
      name: value,
      num: formik.getFieldProps('addWorkplace.number').value
    });
  }

/*
const ser = async () => {
  const obj = await fetch('/api/project/structure/serialnumber', {
    method: 'POST',
    body: JSON.stringify({
      project: id
    })
  });
  const result = await obj.json();
  formik.setFieldValue('addWorkplace.number', result.num);
  console.log(result.num)
}
*/
//ser();





  const onSubmitForm = (event) => {
    event.preventDefault()
  }

  return (
    <Portal id={'addWorkplace'}>
      <Dialog
        isRender={isRender}
        isClose={true}
        centered={true}
        width={'700px'}
        onClose={closeShowAddWorkplace}
        title={'ДОБАВИТЬ РАБОЧЕЕ МЕСТО'}
        settings={{
          title: {
            size: 14,
            fWeight: 600,
            lHeight: 17,
            align: 'left',
            margin: '-2px -20px 40px'
          },
          close: {
            height: 28,
            width: 28,
            right: '8px',
            top: '8px',
          }
        }}
      >
        <form onSubmit={onSubmitForm}>

        <table>
        <tr>
          <td width="20%">
            <FieldInput
              margin={'0 0 30px'}
              placeholder={'Номер РМ'}
              formik={formik}
              name={'addWorkplace.number'}

              />
          </td>
          <td witdh = "80%">
            <FieldInput
              margin={'0 0 30px'}
              placeholder={'Наименование рабочего места'}
              formik={formik}
              name={'addWorkplace.name'}
            />
          </td>
        </tr>
        </table>
          <Styled.Buttons jContent={'center'}>
            <Button
              variant={'secondary'}
              onClick={onAddWorkplace}
            >
              Добавить
            </Button>
          </Styled.Buttons>
      </form>
    </Dialog>
  </Portal>
)}

